* {
  box-sizing: border-box;
  margin: 0;
}
.loginWrap {
  width: 1021px;
  margin: 0 auto;
  padding-top: 180px;
  height: 310px;
}
.loginWrap .midline {
  height: 310px;
  margin: 0 10px;
  background: #666;
  display: inline-block;
}
.loginWrap .loginContent {
  width: 300px;
  height: 310px;
  display: inline-block;
  background: #f2f2f5;
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
  vertical-align: top;
}
.loginWrap .loginContent .loginBox {
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
}
.loginWrap .loginContent .label {
  display: flex;
}
.loginWrap .loginContent .label .labelItem {
  width: 33.33%;
  font-size: 14px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #dadada;
  cursor: pointer;
  color: #808080;
}
.loginWrap .loginContent .label .activeLabel {
  border-bottom: 3px solid #388ccc;
  color: #000;
}
.loginWrap .loginContent .accountLogin {
  margin-top: 20px;
  position: relative;
}
.loginWrap .loginContent .accountLogin .sendCode {
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 12px;
  cursor: pointer;
}
.loginWrap .loginContent .accountLogin .inputBox {
  display: flex;
  border: 1px solid #dadada;
  margin-top: 12px;
  padding-left: 10px;
  border-radius: 2px;
  position: relative;
}
.loginWrap .loginContent .accountLogin .inputBox .iconfont {
  align-self: center;
}
.loginWrap .loginContent .accountLogin .tipMsg {
  color: red;
  font-size: 12px;
  margin-top: 12px;
  height: 16px;
}
.loginWrap .loginContent .accountLogin .tipMsg .icon-gantanhao {
  font-size: 12px;
  margin-right: 5px;
}
.loginWrap .loginContent .accountLogin input {
  width: 100%;
  border: 1px solid #dadada;
  line-height: 32px;
  height: 32px;
  border: none;
  outline: none;
  padding-left: 10px;
}
.loginWrap .loginContent .accountLogin .loginBtn {
  width: 100%;
  line-height: 34px;
  background: #388ccc;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  margin-top: 15px;
  font-size: 16px;
  cursor: pointer;
}
.sliderWrapper {
  width: 660px;
  height: 310px;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.sliderWrapper .box {
  position: absolute;
  left: 0px;
  top: 0px;
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.sliderWrapper .box li {
  float: left;
}
.sliderWrapper .nav {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  line-height: 10px;
  text-align: center;
}
.sliderWrapper .nav span {
  position: relative!important;
  flex: 0 1 auto!important;
  width: 30px!important;
  height: 6px!important;
  text-indent: -999px !important;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0!important;
  border: none!important;
  margin: 0 3px!important;
  display: inline-block;
}
.sliderWrapper .nav .active {
  background: #fff;
}
.sliderWrapper img {
  width: 660px;
  height: 310px;
}
.mWrap .sliderWrapper {
  width: 100%;
  height: 210px;
}
.mWrap .sliderWrapper img {
  width: 100%;
  height: 210px;
}
.mWrap .sliderWrapper .nav {
  bottom: 20px;
}
.mWrap .loginWrap {
  width: 90%;
  padding-top: 50px;
}
.mWrap .accountLogin {
  margin-top: 20px;
}
.mWrap .accountLogin input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  line-height: 40px;
  outline: none;
  font-size: 13px;
}
.mWrap .loginBtn {
  width: 100%;
  background: #388ccc;
  color: #fff;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
  border: 1px solid #388ccc;
  margin-top: 10px;
  outline: none;
}
.mWrap .elseLogin {
  width: 100%;
  color: #388ccc;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
  border: 1px solid #388ccc;
  margin-top: 10px;
  background: #fff;
  outline: none;
}
.mWrap .smsCon {
  position: relative;
}
.mWrap .smsCon .sendBtn {
  position: absolute;
  right: 0;
  line-height: 40px;
  font-size: 13px;
  bottom: 0;
}
.mWrap .tipMsg {
  color: red;
  font-size: 12px;
  margin-top: 12px;
  height: 16px;
}
.mWrap .tipMsg .icon-gantanhao {
  font-size: 12px;
  margin-right: 5px;
}

